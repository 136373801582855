import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import AboutUsSection from "../components/sections/AboutUsSection";
import { Button, ButtonGroup, Grid, Typography } from "@mui/material";

const AboutUs = () => {
  const matches = useMediaQuery("(max-width:600px)");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [activeSection, setActiveSection] = useState("Historique"); // Set default to "Historique"

  const getPageData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
        params: { name: "AboutUs" },
      })
      .then((response) => {
        const formattedData = {
          Historique: {
            image: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.image1}`,
            text: response.data.text1,
          },
          Mission: {
            image: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.image2}`,
            text: response.data.text2,
          },
          
          Vision: {
            image: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.image3}`,
            text: response.data.text3,
          },
          Valeurs: {
            image: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.image4}`,
            text: response.data.text4,
          },
        };
        setData(formattedData);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
      });
  };

  useEffect(() => {
    getPageData();
  }, []);

  if (loading || !data) return <div>Loading...</div>; // Loading state

  return (
    <>
      {/* Render the AboutUsSection with active section data */}
      <AboutUsSection data={data[activeSection]} />

      {/* Button Group for selecting sections */}
      <Grid container spacing={2} justifyContent="center" style={{ marginTop: '16px', zIndex: 10 }}>
        {Object.keys(data).map((key) => (
          <Grid item key={key} xs={6} sm={3}> {/* xs=6 for small screens, sm=3 for larger screens */}
            <Button
              fullWidth // Makes the button responsive, filling the space on small screens
              onClick={() => setActiveSection(key)} // On button click, update active section
              color={activeSection === key ? "primary" : "secondary"} // Highlight the active button
              style={{ zIndex: 10 }} // Ensure buttons are above everything else
            >
              {key}
            </Button>
          </Grid>
        ))}
      </Grid>

      {/* Display the corresponding text for the active section */}
      <Grid container spacing={2} justifyContent="center" style={{ marginTop: '16px' }}>
  <Grid item xs={12}>
    {/* Use dangerouslySetInnerHTML to render HTML content */}
    <Typography variant="body1" style={{ margin: '16px', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: data[activeSection]?.text }} />
  </Grid>
</Grid>

    </>
  );
};

export default AboutUs;
