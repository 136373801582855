import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Logo from "./partials/Logo";
import { Home, RoomService } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import ListAltIcon from "@mui/icons-material/ListAlt";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ImageIcon from "@mui/icons-material/Image";
import QuizIcon from "@mui/icons-material/Quiz";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../features/user/userSlice";

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const [isActive, setIsactive] = useState(false);
  const state = useSelector((state) => state.user.data);
  const dispatch = useDispatch();

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", clickOutside);
    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", clickOutside);
      closeMenu();
    };
  }, [isActive]);

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const logout = () => {
    dispatch(
      setUserData({
        user_id: null,
        password: null,
        user_type: null,
      })
    );
    window.location.reload();
  };

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };

  const classes = classNames(
    "site-header",
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  return (
    <header {...props} className={classes}>
      <div className="" style={{ marginLeft: 10, marginRight: 10 }}>
        <div
          className={classNames(
            "site-header-inner",
            bottomDivider && "has-bottom-divider"
          )}
        >
          <Logo />
          {!hideNav && (
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames("header-nav", isActive && "is-active")}
              >
                <div className="header-nav-inner">
                  <ul
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      marginLeft: "18%",
                    }}
                    className={classNames(
                      "list-reset text-xs",
                      navPosition && `header-nav-${navPosition}`
                    )}
                  >
                    <li>
                      <Link to="/" onClick={closeMenu}>
                        <Home />
                        Accueil
                      </Link>
                    </li>

                    <li>
                      <Link to="/about-us" onClick={closeMenu}>
                        <InfoIcon />À Propos
                      </Link>
                    </li>

                    <li>
                      <Link to="/services" onClick={closeMenu}>
                        <ListAltIcon />
                        Services
                      </Link>
                    </li>

                    <li>
                      <Link to="/news" onClick={closeMenu}>
                        <NewspaperIcon />
                        Actualités
                      </Link>
                    </li>

                    <li>
                      <Link to="/contactus" onClick={closeMenu}>
                        <ContactMailIcon />
                        Contactez-nous
                      </Link>
                    </li>
                    <li>
                      <Link to="/gallery" onClick={closeMenu}>
                        <ImageIcon />
                        Galerie
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://panneau.centre-pad.com/#/statistiques"
                        onClick={closeMenu}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <QuizIcon /> 
                        Nos progrès
                      </a>
                    </li>
                  </ul>
                  {!hideSignin && (
                    <ul className="list-reset header-nav-right">
                      <li className={state.user_id ? "" : "hidden"}>
                        {/* <Link
                          to="/results"
                          className="button button-primary button-wide-mobile button-sm"
                          onClick={logout}
                        >
                          Déconnexion
                        </Link> */}
                      </li>
                    </ul>
                  )}
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
