import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import styles from "../../assets/styles/ServicesSection.module.css";
import axios from "axios";
import { Link } from "react-router-dom";

const propTypes = {};

const defaultProps = {};

const ServicesSection = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const [services, setServices] = useState([]);
  const cardRefs = useRef([]);

  const getServices = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/services/get`, {
        params: {},
      })
      .then((response) => {
        setServices(response.data);
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    const adjustCardHeights = () => {
      cardRefs.current.forEach((card) => {
        if (card) card.style.height = "auto";
      });

      const heights = cardRefs.current.map((card) => card?.offsetHeight || 0);
      const maxHeight = Math.max(...heights);

      cardRefs.current.forEach((card) => {
        if (card) card.style.height = `${maxHeight}px`;
      });
    };

    if (services.length > 0) {
      adjustCardHeights();
    }

    window.addEventListener("resize", adjustCardHeights);
    return () => window.removeEventListener("resize", adjustCardHeights);
  }, [services]);

  return (
    <section
      className={`${styles.whiteSection} ${styles.full_width} ${styles.clearfix} ${styles.coursesSection}`}
      id="Services"
    >
      <div className={styles.container}>
        <div className={`${styles.sectionTitle} ${styles.text_center}`}>
          <h2>
            <span
              className={`${styles.shape} ${styles.shape_left} ${styles.bg_color_4}`}
            ></span>
            <span>Nos Services</span>
            <span
              className={`${styles.shape} ${styles.shape_right} ${styles.bg_color_4}`}
            ></span>
          </h2>
        </div>

        <div
  className={styles.row}
  style={{
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",
  }}
>
  {services?.map((service, index) => (
    <div
      key={service.id}
      ref={(el) => (cardRefs.current[index] = el)}
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "20px",
        flex: "1 1 calc(25% - 20px)", // Default for large screens
        maxWidth: "calc(25% - 20px)",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          padding: "15px",
          flex: "1",
        }}
      >
        <img
          src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/servicesImages/${service?.image}`}
          alt={service.title}
          style={{
            width: "100%",
            height: "200px",
            objectFit: "cover",
            borderRadius: "8px 8px 0 0",
          }}
        />
        <h3>{service.title}</h3>
        <p>{service.short_description}</p>
      </div>
    </div>
  ))}
</div>

<style>
  {`
    @media (max-width: 1024px) {
      .${styles.row} > div {
        flex: 1 1 calc(50% - 20px) !important; /* 2 cards per row */
        max-width: calc(50% - 20px) !important;
      }
    }

    @media (max-width: 768px) {
      .${styles.row} > div {
        flex: 1 1 100% !important; /* 1 card per row */
        max-width: 100% !important;
      }
    }
  `}
</style>

      </div>
    </section>
  );
};

ServicesSection.propTypes = propTypes;
ServicesSection.defaultProps = defaultProps;

export default ServicesSection;
